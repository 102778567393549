<template>
  <div>
    <p>Land und Sprache auswählen:</p>
    <select name="country" v-model="country">
      <option
        :value="country.country_name"
        v-for="country in countryCodes"
        :key="country.country_name"
      >
        {{ country.country_name }}
      </option>
    </select>
    <select name="language" v-model="language">
      <option
        :value="lang.language_code"
        v-for="lang in languageCodes"
        :key="lang.language_code"
      >
        {{ lang.language_name }}
      </option>
    </select>
  </div>
</template>

<script setup>
const { ref } = require("@vue/reactivity");
const { watch, watchEffect } = require("@vue/runtime-core");

const countryCodes = require("./countryCodes.js");
const languageCodes = require("./languageCodes.js");

const emits = defineEmits(["update:country", "update:language"]);
const country = ref("Germany");
const language = ref("de");

watchEffect(() => {
  emits("update:country", country.value);
  emits("update:language", language.value);
});
</script>

<style>
select {
  height: 35px;
  padding: 5px;
  margin: 0 10px;
  border: 2px solid #cccccc;
  border-radius: 15px;
  font: inherit;
  color: #2c3e50;
}
.flag {
  height: 20px;
  width: auto;
}
</style>